import * as React from 'react';
import { Col, Row } from 'react-bootstrap';

import './SafetyStandards.scss';

function SafetyStandards() {
  return (
    <main className="Legal">
      <Row className="justify-content-center">
        <Col sm={12} md={9}>
          <h1>Safety Standards Policy</h1>

          <h2>Prohibited Content</h2>
          <p>Our app strictly prohibits:</p>
          <ul>
            <li>Sharing or promoting content related to child sexual abuse and exploitation.</li>
            <li>Any activity that endangers the safety of children or violates their rights.</li>
          </ul>

          <h2>Content Moderation</h2>
          <p>We actively monitor user-generated content using:</p>
          <ul>
            <li>Automated content filters to detect and block inappropriate materials.</li>
            <li>Manual review by our moderation team for flagged content.</li>
          </ul>

          <h2>Reporting Mechanisms</h2>
          <p>Users can report inappropriate content by:</p>
          <ul>
            <li>Using the “Report” button available in the app for each piece of content.</li>
            <li>
              Contacting our support team at <a href="mailto:destek@bizdenkimvar.com">destek@bizdenkimvar.com</a>.
            </li>
          </ul>

          <h2>Compliance with Laws</h2>
          <p>We comply with all applicable laws and regulations, including:</p>
          <ul>
            <li>The Children’s Online Privacy Protection Act (COPPA).</li>
            <li>The General Data Protection Regulation (GDPR).</li>
            <li>International standards against child exploitation.</li>
          </ul>
        </Col>
      </Row>
    </main>
  );
}

export default SafetyStandards;
